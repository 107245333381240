import './../../scss/blocks/_flats.scss'
import oneLineMobileSlider from './../components/oneLineMobileSlider'
import './../../scss/blocks/_modal.scss'

import {
    handleModalCloseButton,
    handleModalCloseEscapeButton,
    handleModalCloseBackground,
} from '../components/modalInit'

// General modal open handler
const openBenefitModal = (event) => {
    const target = event.target
    const href = target.getAttribute('href')

    if (href && href.includes('benefitdetails')) {
        event.preventDefault()
        const base = document.querySelector('.flats')

        // get ID of modal from data-id
        const id = target.closest('.card-flat').getAttribute('data-id')
        const modalSelector = '.js-benefits-modal[data-id="' + id + '"]'
        const modal = base.querySelector(modalSelector)

        if (base && modalSelector) {
            modal.classList.add('is-opened')
        }
    }
}

// Initialize all modals and event listeners
const initModalEventListeners = () => {
    document.body.addEventListener('click', openBenefitModal)

    // Reuse the modal close background and button handlers
    document.querySelectorAll('.js-benefits-modal').forEach(modal => {
        modal.addEventListener('click', handleModalCloseBackground)
    })

    document.querySelectorAll('.olap-modal-close').forEach(button => {
        button.addEventListener('click', handleModalCloseButton)
    })
    document.addEventListener('keydown', function(event) {
        if (event.key === 'Escape') {
            handleModalCloseEscapeButton()
        }
    })
}

// Initialize slider
const initFlatsSlider = () => {
    const sliderOptions = {
        autoHeight: false,
        slidesPerView: 1.1,
        initialSlide: 0,
        spaceBetween: 20,
    }

    oneLineMobileSlider('.flats__slider', '46.45rem', sliderOptions)
}

// DOMContentLoaded handler
const onDOMContentLoaded = () => {
    initFlatsSlider()
    initModalEventListeners()
}

// Register DOMContentLoaded event
document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
